body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #fff1dd !important;
}

.ui.segment {
  background-color: rgba(200, 200, 200, 0.1) !important
}

.ui.segment a {
  color: #381600 !important
}

.ui.segment a:hover {
  color: #650000 !important
}

.flex-card-container {
  display: flex;
}

.flex-card-container .ui.card {
  margin: 0 1em 0 0;
  padding: 0.4em
}

@media only screen and (max-width: 767px) {
  .App .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}


/* Segment overrides - less papery */

.App .ui.container .ui.segment {
  border: 0;
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #FFD1C7 !important;
  box-shadow: none !important;
  padding: 1em 0 !important;
}

.ui.card ul {
  padding-left: 0;
}

.ui.card li {
  list-style-type: none;
}

.home-page {
  position: relative;
}

.home-page .menu {
  margin: 0 !important;
}

.home-page .title h1 {
  padding-left: 1em
}

.home-header {
  background-image: url("/splash.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.round-logos {
  position: absolute;
  right: 10px;
  top: 50px;
  display: flex;
}

@media only screen and (max-width: 400px) {
  .round-logos {
    top: 100px;
  }
}

.round-logos>.round-logo {
  height: 120px;
  width: 120px;
}

.round-logos>.round-logo {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.fla {
  background-image: url("/fla-round.png");
}

.ila {
  background-image: url("/ila-round.png")
}

.home-page .title {
  background-color: black;
  color: #fff1dd;
  padding: 1.5em 0 2em 0;
  margin-bottom: 2em;
}


/* about page */

.about-page .logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.about-page .center.aligned.column {
  margin: 0;
  padding: 0
}

.about-page .logos img.logo {
  /* max-height: 60px; */
  padding: 1em;
}

.ui.button.basic {
  margin-bottom: 5px;
}

.org {
  margin: 2em;
}

.org h3 {
  margin: 0 !important
}

ul.tool-content {
  padding-left: 0;
}

ul.content-row {
  padding-left: 0;
}

ul.content-row li {
  list-style-type: none;
  margin: 1em 0;
  padding-bottom: 1em;
  border-bottom: 1px solid #FFD1C7;
  display: flex;
  justify-content: space-between;
}

ul.content-row li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.rating label {
  color: #666;
}

.rating label.pre {
  padding-right: 0.5em
}

.rating label.post {
  padding-left: 0.5em
}

.rating label.selected {
  font-weight: bold;
}

.ui.button.contributed-by {
  background-color: #fbbd08!important;
  color: #fff!important;
  padding: 0.4em;
  margin-left: 1em;
}

.ui.segment a {
  color: #832312 !important
}

.content-list a {
  display: block;
}

hr {
  border-top: 0px;
  border-bottom: 1px solid #FFD1C7;
}

.tools.content-list a,
.templates.content-list a {
  border-bottom: 1px solid #FFD1C7;
}

.content-list a:hover {
  background-color: #ffffdd;
}

.padded a {
  padding: 0.6em 0.4em !important;
  font-size: 1.1em;
}

.contributors {
  margin-top: 0;
}

.contributors a {
  font-size: 1.1em;
}

.contributors .name {
  margin-bottom: 0;
}

.contributor .meta {
  margin-top: 0;
  font-size: 0.9em;
  color: black;
}

.content img {
  max-width: 100%;
}

.pdf-download {
  margin: 1em 0;
}

.ui.segment.media {
  display: flex;
  flex-wrap: wrap;
}

.ui.inverted.menu {
  flex-flow: wrap;
}

.open-image {
  margin: 0;
  position: relative;
}

.open-image-icon {
  position: absolute;
  top: 2px;
  right: 0;
}

.search-input {
  margin-right: 0.4em;
}


.pronunciation, .artwork-credit p{ color: #666;}
.artwork-credit-thumbnail{width:200px; height:auto; margin-right:20px;}
.filter-button .ui.button {
    font-weight: bold !important;
}
